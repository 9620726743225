import { FirebaseError } from 'firebase/app'
import { UserCredential, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, sendPasswordResetEmail } from 'firebase/auth'
import React from 'react'
import { auth } from '../firebase'
import { Currency } from '../interfaces/interfaces'

export const clearFields = (refs: Array<React.MutableRefObject<HTMLInputElement | null>>) => {
  refs.forEach(ref => {
    if (ref && ref.current) {
      ref.current.value = ''
    }
  })
}

export const handleAuthError = (error: FirebaseError) => {
  const errorCode = error.code
  switch (errorCode) {
    case 'auth/email-already-in-use':
      return ('Ese correo ya está en uso')
    case 'auth/invalid-credential':
      return ('Los datos no son correctos')
    case 'auth/wrong-password':
      return ('Contraseña incorrecta')
    case 'auth/user-not-found':
      return ('El usuario no existe')
    default:
      return ('Ha ocurrido un error desconocido.')
  }
}

export const loginWithEmail = async (
  emailRef: React.MutableRefObject<HTMLInputElement | null>,
  passwordRef: React.MutableRefObject<HTMLInputElement | null>
) => {
  const email = emailRef.current?.value
  const password = passwordRef.current?.value
  if (!email || !password) return
  if (password.length < 6) return 'La contraseña debe tener al menos 6 caracteres'

  try {
    await signInWithEmailAndPassword(auth, email, password)
    clearFields([emailRef, passwordRef])
    return true
  } catch (error) {
    const errorMsg = handleAuthError(error as FirebaseError)
    return errorMsg
  }
}

export const handleSignIn = async (
  nameRef: React.MutableRefObject<HTMLInputElement | null>,
  emailRef: React.MutableRefObject<HTMLInputElement | null>,
  passwordRef: React.MutableRefObject<HTMLInputElement | null>,
  repeatPasswordRef: React.MutableRefObject<HTMLInputElement | null>,
  languageRef: React.MutableRefObject<HTMLSelectElement | null>,
  currencyRef: React.MutableRefObject<HTMLSelectElement | null>,
  userTimeZone: string,
  source: string,
  device: 'android' | 'iphone' | 'pc'
) => {
  const name = nameRef.current?.value
  const email = emailRef.current?.value
  const password = passwordRef.current?.value
  const currency = currencyRef.current?.value as Currency
  const repeatPassword = repeatPasswordRef.current?.value
  const defaultLanguage = languageRef.current?.value || 'es'
  if (!email || !password || !name || !defaultLanguage) return
  if (password.length < 6) {
    return 'La contraseña debe tener al menos 6 caracteres'
  }
  if (password !== repeatPassword) {
    return 'Las contraseñas no coinciden'
  }

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    if (userCredential.user) {
      await updateProfile(userCredential.user, { displayName: name })
      await saveUserInBD(name, email, userCredential, defaultLanguage, currency, userTimeZone, source, device)
      clearFields([nameRef, emailRef, passwordRef, repeatPasswordRef])
      return true
    };
  } catch (error) {
    console.error(error)
    const errorMsg = handleAuthError(error as FirebaseError)
    return errorMsg
  }
}

const saveUserInBD = async (name: string, email: string, userCredential: UserCredential, defaultLanguage: string, currency: Currency, userTimeZone: string, source: string, device: 'android' | 'iphone' | 'pc') => {
  try {
    const response = await fetch('/api/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        email,
        firebaseId: userCredential.user.uid,
        defaultLanguage,
        currency,
        userTimeZone,
        source,
        device
      })
    })

    const data = await response.json()
    if (!response.ok) console.error(data.error)
  } catch (error) {
    console.error(error)
  }
}

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (error) {
    console.error('Error en resetPassword:', error)
  }
}
