'use client'

import { clearFields, handleSignIn } from '@/lib/utils/login'
import React, { useRef, useState } from 'react'
import LoadingSpinner from '../LoadingSpinner'
import { barlowCondensed, comfortaa } from '@/lib/fonts'
import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { Locale } from '@/i18n/routing'

interface Props {
  changeForm: () => void
  userLanguage: Locale
}

const SigninForm = ({ changeForm, userLanguage }: Props) => {
  const t = useTranslations('SigninForm')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const nameRef = useRef<HTMLInputElement | null>(null)
  const emailRef = useRef<HTMLInputElement | null>(null)
  const passwordRef = useRef<HTMLInputElement | null>(null)
  const repeatPasswordRef = useRef<HTMLInputElement | null>(null)
  const languageRef = useRef<HTMLSelectElement | null>(null)
  const currencyRef = useRef<HTMLSelectElement>(null)
  // const userCurrency = getDefaultCurrency()
  const userCurrency = '€'
  const router = useRouter()

  const handleChangeForm = () => {
    changeForm()
    setError('')
    clearFields([emailRef, passwordRef])
    if (languageRef.current) {
      languageRef.current.value = 'es'
    }
  }

  const signInWithEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Madrid'
    const source = localStorage.getItem('source') || 'direct'
    const device = getDeviceType()
    const success = await handleSignIn(nameRef, emailRef, passwordRef, repeatPasswordRef, languageRef, currencyRef, userTimeZone, source, device)
    // espera a la confirmación de succes para redireccionar o mostar el error
    if (typeof success === 'string') {
      setError(success)
    } else if (success) {
      router.push('/dashboard')
    }
    setLoading(false)
  }

  return (
    <>
      <p style={comfortaa.style} className='new-account-msg'>{t('labels.newAccountMsg')}</p>
      <form onSubmit={signInWithEmail}>
        <input
          style={barlowCondensed.style}
          ref={nameRef}
          name='name'
          type='text'
          required
          placeholder={t('labels.namePlaceholder')}
        />
        <input
          style={barlowCondensed.style}
          ref={emailRef}
          name='email'
          type='email'
          required
          placeholder={t('labels.emailPlaceholder')}
        />
        <input
          style={barlowCondensed.style}
          ref={passwordRef}
          name='password'
          type='password'
          required
          placeholder={t('labels.passwordPlaceholder')}
        />
        <input
          style={barlowCondensed.style}
          ref={repeatPasswordRef}
          name='repeatPassword'
          type='password'
          required
          placeholder={t('labels.repeatPasswordPlaceholder')}
        />
        <label className='label-wrapper' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          {t('default-currency-select')}
          <select style={{ ...barlowCondensed.style, width: '100px' }} ref={currencyRef} name='currency' defaultValue={userCurrency} required>
            <option value='€'>Euro (€)</option>
            <option value='$'>{t('dollar')}</option>
            <option value='£'>{t('pound')}</option>
          </select>
        </label>
        <label className='label-wrapper' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          {t('default-lang-select')}
          <select style={{ ...barlowCondensed.style, width: '100px' }} ref={languageRef} name='language' defaultValue={userLanguage} required>
            <option value='es'>{t('spanish')}</option>
            <option value='en'>{t('ingles')}</option>
            <option value='fr'>{t('french')}</option>
          </select>
        </label>

        <button
          id='crear-cuenta-btn'
          style={barlowCondensed.style}
          disabled={loading}
          type='submit'
          className='login-btn'
        >
          {loading ? <LoadingSpinner /> : t('buttons.createAccount')}
        </button>
      </form>
      <p style={barlowCondensed.style} className='error-msg'>{error && error}</p>
      <button
        style={barlowCondensed.style}
        disabled={loading}
        className='create-account'
        onClick={handleChangeForm}
      >
        {t('buttons.haveAccount')}
      </button>
    </>
  )
}
export default SigninForm

const getDeviceType = () => {
  if (typeof navigator === 'undefined') return 'pc' // Asumir PC en entorno no navegador

  const ua = navigator.userAgent

  if (/android/i.test(ua)) {
    return 'android'
  } else if (/iPhone|iPad|iPod/i.test(ua)) {
    return 'iphone'
  } else {
    return 'pc'
  }
}

// const getDefaultCurrency = () => {
//   if (typeof navigator === 'undefined') return '€'

//   const locales = navigator.languages || [navigator.language]

//   for (const locale of locales) {
//     const countryCode = locale.split('-')[1]?.toUpperCase()
//     if (!countryCode) continue

//     // Países con libra esterlina
//     if (countryCode === 'GB') return '£'

//     // Principales países con dólar
//     if (['US', 'CA', 'AU', 'NZ', 'MX', 'BR'].includes(countryCode)) return '$'

//     // Países de la zona Euro
//     if ([
//       'ES', 'FR', 'DE', 'IT', 'NL', 'BE', 'PT', 'IE',
//       'AT', 'FI', 'GR', 'LU', 'CY', 'MT', 'SI', 'SK',
//       'EE', 'LV', 'LT'
//     ].includes(countryCode)) return '€'
//   }

//   // Fallback basado en idioma principal
//   const primaryLanguage = locales[0].split('-')[0].toLowerCase()
//   return primaryLanguage === 'en' ? '$' : '€'
// }
