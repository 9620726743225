/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-indent */
'use client'

import { useEffect, useState } from 'react'
import LoginForm from '@/components/login/LoginForm'
import SigninForm from '@/components/login/SigninForm'
import '../../../../sass/Login.scss'
import { useAuthContext } from '@/contexts/AuthContext'
import { useRouter } from 'next/navigation'
import '../../../../sass/landing/LandingGlobal.scss'
import LandingFooter from '@/components/landingPage/LandingFooter'
import LoadingScreen from '@/components/LoadingScreen'
import LandingHeader from '@/components/landingPage/LandingHeader'
interface Props {
	params: {
		form: any,
		locale: 'es'| 'en'
	 }
}

const Login = ({ params }: Props) => {
  const signupForm = !!(params.form && params.form.length > 0 && params.form[0] === 'signup')
  const userLanguage = params.locale
  const router = useRouter()
  const { user } = useAuthContext()
  const [formType, setFormType] = useState(signupForm)

  const changeForm = () => {
    setFormType(!formType)
  }

  useEffect(() => {
    if (!user) return
    router.push('/dashboard')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  return (
		<>
			{!user
			  ? (
					<>
						<LandingHeader />
						<div className='landing-page'>
							<div className='login'>
									{/* <Link href='/'>
										<Image src='/img/logo-alone.svg' height={500} width={500} alt='logo barmanager' />
									</Link> */}
								{!formType ? <LoginForm changeForm={changeForm} /> : <SigninForm changeForm={changeForm} userLanguage={userLanguage} />}
							</div>
						</div>
						<LandingFooter />
     </>
			    )
			  : (
				<LoadingScreen />
			    )}
		</>
  )
}
export default Login
