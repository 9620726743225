import Image from 'next/image'
import React, { useState, useEffect } from 'react'
import { useTranslations } from 'next-intl'
import '../sass/LoadingScreen.scss'

const LoadingScreen = () => {
  const t = useTranslations('LoadingScreen')
  const [messageIndex, setMessageIndex] = useState<number>(0)

  const messages = [
    t('loadingDashboard'),
    t('adjustingSecretSauce'),
    t('addingIceToCocktails'),
    t('polishingPixels'),
    t('preparingExtraRound'),
    t('addingCherryOnTop')
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 2500) // Cambia de mensaje cada 2.5 segundos

    return () => clearInterval(interval)
  }, [messages.length])

  return (
    <div className='loading-screen'>
      <div className='container'>
        <Image src='/img/barmanager-logo.svg' height={500} width={500} alt='logo barmanager' />
        <div className='loader' />
      </div>
      <div className='terminal'>
        <p>
          {messages[messageIndex]}
        </p>
      </div>
    </div>
  )
}

export default LoadingScreen
