'use client'

import React, { useRef, useState } from 'react'
import { clearFields, loginWithEmail, resetPassword } from '@/lib/utils/login'
import LoadingSpinner from '../LoadingSpinner'
import { barlowCondensed, comfortaa } from '@/lib/fonts'
import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'

interface Props {
  changeForm: () => void
}

const LoginForm = ({ changeForm }: Props) => {
  const t = useTranslations('LoginForm')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const emailRef = useRef<HTMLInputElement | null>(null)
  const passwordRef = useRef<HTMLInputElement | null>(null)
  const router = useRouter()

  const handleChangeForm = () => {
    changeForm()
    setError('')
    clearFields([emailRef, passwordRef])
  }

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const success = await loginWithEmail(emailRef, passwordRef)
    // espera a la confirmación de succes para redireccionar o mostar el error
    if (typeof success === 'string') {
      setError(success)
    } else if (success) {
      router.push('/dashboard')
    }
    setLoading(false)
  }

  const handleResetPassword = async () => {
    if (emailRef.current?.value) {
      const result = await resetPassword(emailRef.current.value)
      if (result === true) {
        alert(t('alerts.resetPasswordSuccess'))
      } else {
        setError(t('alerts.resetPasswordFailure'))
      }
    } else {
      setError(t('alerts.emailRequired'))
    }
  }

  return (
    <>
      <p style={comfortaa.style} className='new-account-msg'>{t('labels.newAccountMsg')}</p>
      <form onSubmit={handleLogin}>
        <input
          className={barlowCondensed.className}
          ref={emailRef}
          name='email'
          type='email'
          required
          placeholder={t('placeholders.email')}
        />
        <input
          className={barlowCondensed.className}
          ref={passwordRef}
          name='password'
          type='password'
          required
          placeholder={t('placeholders.password')}
        />
        <button
          style={barlowCondensed.style}
          disabled={loading}
          type='submit'
          className='login-btn'
        >
          {loading ? <LoadingSpinner /> : t('buttons.login')}
        </button>
      </form>
      <p style={barlowCondensed.style} className='error-msg'>{error && error}</p>
      <button
        style={barlowCondensed.style}
        disabled={loading}
        className='reset-password'
        onClick={handleResetPassword}
      >
        {t('buttons.forgotPassword')}
      </button>
      <button
        style={barlowCondensed.style}
        disabled={loading}
        className='create-account'
        onClick={handleChangeForm}
      >
        {t('buttons.createAccount')}
      </button>
    </>
  )
}
export default LoginForm
